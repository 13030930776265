var $speed = 400;

var accordion = (function () {

    var $accordion = $('.js-m-accordion');
    var $accordion_header = $accordion.find('.m-accordion-header');

    // default settings
    var settings = {
        // animation speed
        speed: $speed,

        // close all other accordion items if true
        oneOpen: false
    };

    return {
        // pass configurable object literal
        init: function ($settings) {
            $accordion_header.on('click', function () {
                accordion.toggle($(this));
            });

            $.extend(settings, $settings);

            // override oneOpen with data
            if (typeof $accordion.data('one-open') !== 'undefined') {
                settings.oneOpen = !!$accordion.data('one-open');
            }

            // ensure only one accordion is active if oneOpen is true
            if (settings.oneOpen && $('.m-accordion-item.is-active').length > 1) {
                $('.m-accordion-item.is-active:not(:first)').removeClass('is-active');
            }

            // reveal the active accordion bodies
            $('.m-accordion-item.is-active').find('> .m-accordion-body').show();
        },
        toggle: function ($this) {

            if (settings.oneOpen && $this[0] != $this.closest('.js-m-accordion').find('> .m-accordion-item.is-active > .m-accordion-header')[0]) {
                $this.closest('.js-m-accordion')
                    .find('> .m-accordion-item')
                    .removeClass('is-active')
                    .find('.m-accordion-body')
                    .slideUp()
            }

            // show/hide the clicked accordion item
            $this.closest('.m-accordion-item').toggleClass('is-active');
            $this.next().stop().slideToggle(settings.speed);
        }
    }
})();

$(document).ready(function () {
    accordion.init({speed: $speed, oneOpen: true});
});