jQuery(document).ready(function ($) {

    $("div#mob_search").mmenu({
        "slidingSubmenus": false,
        "extensions": [
            "pagedim-black",
            "theme-black",
            "position-front",
            "position-right"
        ]

    });

    var search_header_API = $("#mob_search").data("mmenu");

    $(".js-close-search").click(function () {
        search_header_API.close();
    });



});