/* Toggle module. Used on footer & video page */
(function () {

    var el = $('.js-c-footer-seo');

    if (!el.length) return false;

    if (el.find('.c-footer-seo-wrap p').length > 1) {
        el.addClass('c-footer-seo--enabled');
    }

    el.find('.js-c-footer-seo-btn').on('click', function (e) {
        el.toggleClass("c-footer-seo--active");

    });

})();