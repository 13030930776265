$(document).on('click', '.js-m-collapse', function () {

    if ($(this).data('btn-hide')) {
        $(this).addClass('u-none');
    }

    var $target_value = $(this).data('target');
    var $target_block = $('.m-collapse--' + $(this).data('target'));


    if ($target_block.is(':visible')) {
        $('[data-target="'+$target_value+'"]').removeClass('is-active');
        $target_block.velocity("slideUp", {duration: 450, easing: 'easeOutQuart'}).parent().removeClass('is-active');
    } else {
        $('[data-target="'+$target_value+'"]').addClass('is-active');
        $target_block.velocity("slideDown", {duration: 400, easing: 'easeOutQuart'}).parent().addClass('is-active');
    }
    return false;
});