// Modal system. Just use class js-m-modal + data-modal="id" + data-effect="slide-top"
;
(function () {

    var modalDuration = 800,
        modalDelay = 350,
        modalEasing = '[0.23, 1, 0.32, 1]';

    var modules = {
        $window: $(window),
        $html: $('html'),
        $body: $('body'),
        $container: $('.m-modal-wrap'),

        init: function () {
            $(function () {
                modules.modals.init();
            });
        },

        modals: {
            trigger: $('.js-m-modal'),
            modal: $('.m-modal'),
            scrollTopPosition: null,

            init: function () {
                var self = this;

                if (self.trigger.length > 0 && self.modal.length > 0) {
                    modules.$container.append('<div class="m-modal-overlay"></div>');

                    self.triggers();
                }
            },

            triggers: function () {
                var self = this;

                self.trigger.on('click', function (e) {
                    e.preventDefault();

                    var $trigger = $(this);

                    self.openModal($trigger, $trigger.data('modalTarget'), $trigger.data('modalEffect'));
                });

                $('.m-modal-overlay').on('click', function (e) {
                    e.preventDefault();
                    self.closeModal();
                });

                $('.m-modal-dialog').on('click', function (e) {
                    e.preventDefault();
                    self.closeModal();
                });

                $('.m-modal-content').on('click', function (e) {
                    e.stopPropagation();
                });

                modules.$html.on('keydown', function (e) {
                    if (e.keyCode === 27) {
                        self.closeModal();
                    }
                });

                $('.js-modal-close').on('click', function (e) {
                    e.preventDefault();
                    self.closeModal();
                });
            },

            openModal: function (_trigger, _modalTarget, _modalEffect) {
                var self = this,
                    scrollTopPosition = modules.$window.scrollTop(),
                    $targetModal = $('#' + _modalTarget);


                self.scrollTopPosition = scrollTopPosition;

                modules.$html.addClass('m-modal--shown');
                modules.$html.css('overflow', 'hidden');
                modules.$body.css('overflow', 'hidden');

                $(document).trigger('modalBeforeOpen', [_modalTarget]);

                // modules.$body.addClass('u-scroll--stop');
                // modules.$body.css('top', -scrollTopPosition);

                $targetModal.css('overflow', 'hidden');
                $targetModal.addClass('m-modal--active').attr('data-modal-effect', _modalEffect);
                var $targetDialog = $targetModal.find('.m-modal-dialog');
                $targetDialog.removeAttr("style");

                $(document).trigger('modalOpened', [_modalTarget]);

                // Use velocity js for this

                if (_modalEffect === 'slideUpIn') {
                    $targetDialog.velocity('transition.slideUpIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                } else if (_modalEffect === 'slideUpBigIn') {
                    $targetDialog.velocity('transition.slideUpBigIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                } else if (_modalEffect === 'slideDownIn') {
                    $targetDialog.velocity('transition.slideDownIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                } else if (_modalEffect === 'slideDownBigIn') {
                    $targetDialog.velocity('transition.slideDownBigIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                } else if (_modalEffect === 'slideLeftBigIn') {
                    $targetDialog.velocity('transition.slideLeftBigIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                } else if (_modalEffect === 'slideLeftIn') {
                    $targetDialog.velocity('transition.slideLeftIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                } else if (_modalEffect === 'slideRightBigIn') {
                    $targetDialog.velocity('transition.slideRightBigIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                } else if (_modalEffect === 'slideRightIn') {
                    $targetDialog.velocity('transition.slideRightIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                } else if (_modalEffect === 'expandIn') {
                    $targetDialog.velocity('transition.expandIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                } else if (_modalEffect === 'shrinkIn') {
                    $targetDialog.velocity('transition.shrinkIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                } else if (_modalEffect === 'bounceDownIn') {
                    $targetDialog.velocity('transition.bounceDownIn', {
                        duration: modalDuration,
                        easing: modalEasing
                    });
                }

                setTimeout(function () {
                    $targetModal.css('overflow', '');
                }, modalDuration);

                modules.$container.scrollTop(scrollTopPosition);
            },

            closeModal: function () {
                var self = this;
                var $targetModal = $('.m-modal--active');
                var $targetDialog = $targetModal.find('.m-modal-dialog');
                $targetDialog.velocity("reverse", {
                    complete: function() {
                        modules.$html.removeClass('m-modal--shown');
                        modules.$html.css('overflow', 'auto');
                        modules.$body.css('overflow', 'auto');
                        // modules.$body.removeClass('u-scroll--stop');
                        // modules.$body.css('top', '');
                        modules.$window.scrollTop(self.scrollTopPosition);

                        $(document).trigger('modalClosed', [$targetModal.attr('id')]);
                    }
                });

                $targetModal.removeClass('m-modal--active').removeAttr('data-modal-effect');
            }
        }
    };

    modules.init();


})();

// modal scrollbar fix
(function() {
    var div = document.createElement('div');
    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';
    document.body.appendChild(div);
    var scrollbar_width = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    // Events
    $(document)
        .on('modalBeforeOpen', function() {
            $('body').css('padding-right', scrollbar_width);
        })
        .on('modalClosed', function() {
            $('body').css('padding-right', '');
        });
})();

var ModalsFixes = (function(){

    // рџ”— Thijs Huijssoon https://gist.github.com/thuijssoon
    var disableBodyScroll = (function () {

        /**
         * Private variables
         */
        var _selector = false,
            _element = false,
            _clientY;

        /**
         * Polyfills for Element.matches and Element.closest
         */
        if (!Element.prototype.matches)
            Element.prototype.matches = Element.prototype.msMatchesSelector ||
                Element.prototype.webkitMatchesSelector;

        if (!Element.prototype.closest)
            Element.prototype.closest = function (s) {
                var ancestor = this;
                if (!document.documentElement.contains(el)) return null;
                do {
                    if (ancestor.matches(s)) return ancestor;
                    ancestor = ancestor.parentElement;
                } while (ancestor !== null);
                return el;
            };

        /**
         * Prevent default unless within _selector
         *
         * @param  event object event
         * @return void
         */
        var preventBodyScroll = function (event) {
            if (false === _element || !event.target.closest(_selector)) {
                event.preventDefault();
            }
        };

        /**
         * Cache the clientY co-ordinates for
         * comparison
         *
         * @param  event object event
         * @return void
         */
        var captureClientY = function (event) {
            // only respond to a single touch
            if (event.targetTouches.length === 1) {
                _clientY = event.targetTouches[0].clientY;
            }
        };

        /**
         * Detect whether the element is at the top
         * or the bottom of their scroll and prevent
         * the user from scrolling beyond
         *
         * @param  event object event
         * @return void
         */
        var preventOverscroll = function (event) {

            // only respond to a single touch
            if (event.targetTouches.length !== 1) {
                return;
            }

            var clientY = event.targetTouches[0].clientY - _clientY;

            // The element at the top of its scroll,
            // and the user scrolls down
            if (_element.scrollTop === 0 && clientY > 0) {
                event.preventDefault();
            }

            // The element at the bottom of its scroll,
            // and the user scrolls up
            // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
            if ((_element.scrollHeight - _element.scrollTop <= _element.clientHeight) && clientY < 0) {
                event.preventDefault();
            }

        };

        /**
         * Disable body scroll. Scrolling with the selector is
         * allowed if a selector is porvided.
         *
         * @param  boolean allow
         * @param  string selector Selector to element to change scroll permission
         * @return void
         */
        return function (allow, selector) {
            if (typeof selector !== "undefined") {
                _selector = selector;
                _element = document.querySelector(selector);
            }

            if (true === allow) {
                if (false !== _element) {
                    _element.addEventListener('touchstart', captureClientY, { passive: false });
                    _element.addEventListener('touchmove', preventOverscroll, { passive: false });
                }
                document.body.addEventListener("touchmove", preventBodyScroll, { passive: false });
            } else {
                if (false !== _element) {
                    _element.removeEventListener('touchstart', captureClientY, { passive: false });
                    _element.removeEventListener('touchmove', preventOverscroll, { passive: false });
                }
                document.body.removeEventListener("touchmove", preventBodyScroll, { passive: false });
            }
        };
    }());

    function adjustModal(e) {

        var modal = $('.m-modal--active')[0];
        var actual_viewport = window.innerHeight;
        var offset_y = modal.getBoundingClientRect().y;
        var window_width = $(window).width();

        modal.style.top = 0;
        modal.style.bottom = 0;
        var screen_height = modal.scrollHeight;
        var modal_content = $('.m-modal--active .m-modal-content')[0];
        var modal_dialog = $('.m-modal--active .m-modal-dialog')[0];

        // change position for long and simple modals
        if (modal_content.scrollHeight > actual_viewport) {
            modal_content.style.bottom = 'auto';
            if (window_width > 560) {
                modal_content.style.top = 0;
            }
        }

        // Only for mobile Safari in landscape mode, when the modal height doesn't match the actual viewport
        if (!navigator.userAgent.match(/(iPod|iPhone|iPad)/i) || Math.abs(window.orientation) !== 90 || actual_viewport === screen_height) {

            return;

        }

        // On resize event (toolbars have appeared by tapping at the top or bottom area
        if (typeof e !== 'undefined') {

            modal.style.top = (0 - offset_y) + 'px';
            modal.style.bottom = (screen_height - actual_viewport + offset_y) + 'px';

        } else {

            // modal is cropped, adjust its top/bottom
            if (actual_viewport <= screen_height) {

                // page scrolled at the bottom
                if ((document.body.scrollHeight + document.body.getBoundingClientRect().y) === actual_viewport) {

                    modal.style.bottom = 0;
                    modal.style.top = (screen_height - actual_viewport) + 'px';

                } else {

                    modal.style.top = 0;
                    modal.style.bottom = (screen_height - actual_viewport) + 'px';
                }

            }

            if (modal.getBoundingClientRect().y !== 0) { // A little off

                modal.style.top = (parseInt(modal.style.top) - modal.getBoundingClientRect().y) + 'px';
                modal.style.bottom = (parseInt(modal.style.bottom) + modal.getBoundingClientRect().y) + 'px';

            }

            // Extra bug when scrolled near the bottom
            if ((actual_viewport + parseInt(modal.style.top) + parseInt(modal.style.bottom)) > screen_height) {

                modal.style.bottom = (screen_height - actual_viewport - parseInt(modal.style.top)) + 'px';

            }

        }

    }

    // ****************
    // On modals events
    var previousScrollY = window.scrollY;

    $(document).on('modalOpened', function (e) {
        var active_modal = '.m-modal--active .m-modal-dialog';
        $(active_modal)[0].scrollTop = 0;

        disableBodyScroll(true, active_modal);
        previousScrollY = window.scrollY;
        adjustModal();

        window.addEventListener('resize', adjustModal, {passive: false});
    });

    $(document).on('modalClosed', function (e) {
        var $modal_content = $('.m-modal .m-modal-content');
        $('body').animate({scrollTop: previousScrollY}, 200);
        disableBodyScroll(false, '.m-modal-dialog');
        window.removeEventListener('resize', adjustModal, { passive: false });

        $('.m-modal').css({
            top: '',
            bottom: ''
        });
        $modal_content.css('bottom', '');
        $modal_content.css('top', '');
    });

})();