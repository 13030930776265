jQuery(document).ready(function ($) {

    $("#mob_menu").mmenu({
        "counters": true,
        "extensions": [
            "pagedim-black",
            "theme-black",
            "position-front"
        ],
        navbar: {
            title: "New title"
        },
        classNames: {
            selected: "is-active",
            fixedElements: {
                fixed: "c-header"
            }
        }
    });

    var menu_header_API = $("#mob_menu").data("mmenu");

    $(".js-close-mobmenu").click(function () {
        menu_header_API.close();
    });

    console.log('Mobile menu ready');


});