/* Dropdown module. Used on header, video page */
(function () {

    var dropDuration = 250,
        dropDelay = 350,
        dropEasing = '[0.32, 1, 0.23, 1]';

    var el = $('.m-dropdown');
    if (!el.length) return false;

    var $btn = el.find('.js-m-dropdown');

    $btn.on('click', function() {
        var $bl = $(this).parents('.m-dropdown');
        var $in = $bl.find('.m-dropdown-in');
        if ($bl.hasClass('m-dropdown--active')) {
            $bl.removeClass('m-dropdown--active');
            $in.velocity("reverse");
            $in.velocity( { display: "none" });

        } else {
            $bl.addClass('m-dropdown--active');
            $in.velocity('transition.slideDownIn', {
                duration: dropDuration,
                easing: dropEasing
            });
        }
        return false;
    });

    function closeDropdown() {
        $('.m-dropdown--active .m-dropdown-in').velocity('transition.slideUpOut', {
            duration: dropDuration,
            easing: dropEasing
        });
        $('.m-dropdown.m-dropdown--active').removeClass('m-dropdown--active');


    }
    $(document).on('click', function (e) {
        var $target = $(e.target);
        if (!$target.hasClass('js-trigger-click') && !$target.hasClass('m-dropdown-in') && !$target.parents('.m-dropdown-in').length)
            closeDropdown();
    });

    // close dropdown on el click
    $('.js-m-dropdown-close').on('click', function (e) {
        closeDropdown();
    });

})();


/* Dropdown with select module. Used on header, video page */

(function () {

    var el = $('.block-dropdown--select');
    if (!el.length) return false;

    el.each(function() {
        var $dropdown = $(this);
        var $inner = $dropdown.find('.block-dropdown-in');
        var $select = $dropdown.find('select');
        var html = '<ul>';
        var $btn = $dropdown.find('.btn--dropdown');

        var set = function() {
            var $selected = $inner.find('li.selected');
            var value = '';
            $select.find('option').removeAttr('selected');
            if ($selected.length) {
                var index = parseInt($selected.attr('data-index'), 10);
                value = $selected.text();
                $btn.find('span').text(value);
                $select.find('option:eq('+ index +')').attr('selected', true);
            }
            $dropdown.removeClass('open');
            $select.trigger('selectChanged');
        };

        $select.find('option').each(function(index) {
            var $option = $(this);
            var is_selected = $option.is(':selected');
            var text = $option.text();
            html += '<li data-index="'+ index +'" '+ (is_selected?'class="selected"':'') +'>'+
                '<a href="#">'+ text +'</a>' +
                '</li>';
        });

        html += '</ul>';

        $inner.append(html);

        set();

        $inner.find('li a').on('click', function() {
            $(this).parent('li').addClass('selected').siblings('li').removeClass('selected');
            set();
            return false;
        });

    });

})();

