/* Tabs module */
;
(function ($) {

    'use strict';

    var showClass = 'm-js-show-tab';
    var navClass = 'm-tabs--nav';
    var contentClass = 'm-tabs--content';
    var itemClass = 'm-tabs--item';

    var $statusToShow = $('[data-tabs-status]');

    var $clickToShow = $('[data-tabs-show]');

    if (!$clickToShow.length) return false;

    $clickToShow.on('click', function (e) {
        e.preventDefault();

        var $clickToShowData = $(this).data('tabs-show');

        var target = '#' + $(this).data('tabsShow');
        var targetDataTabs = $(this).data('tabs');
        var tabsGroup = $(target).data('tabsGroup');

        // if target has data-tabs none empty attribute
        // set it as tabs_nav, else search for parent
        if (targetDataTabs) {
            var tabs_nav = $('.' + navClass + '[data-tabs="'+ targetDataTabs +'"]');
        } else {
            tabs_nav = $(this).parents('.' + navClass);
        }

        var tabs_content = '#' + tabs_nav.data('tabs');

        $statusToShow.attr('data-tabs-status', $clickToShowData);

        // toggle active class on tab group
        $(tabs_nav).find('[data-tabs-show]').removeClass('is-active');
        $('*[data-tabs="'+ targetDataTabs +'"]').removeClass('is-active');
        $('*[data-tabs-show="'+ $clickToShowData +'"]').addClass('is-active');

        // toggle active tab
        $(tabs_content).children('.' + itemClass).hide();
        $('*[data-tabs-group="'+ tabsGroup +'"]').hide();
        $(target).velocity({
            opacity: 1
        }, {
            duration: 500,
            display: "block"
        });
        // $(target).show();
    })

}(jQuery));

